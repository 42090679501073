<template>
    <NModal
        :show="show"
        preset="card"
        :title="$t('button.change_password')"
        :mask-closable="true"
        :style="{
            width: '400px',
        }"
        @close="$emit('update:close')"
        @mask-click="$emit('update:close')"
    >
        <NSpace vertical>
            <NForm
                ref="formRef"
                :rules="rules"
                :model="model"
                label-placement="left"
                label-width="auto"
                require-mark-placement="right-hanging"
                :show-feedback="false"
            >
                <NFormItem :label="$t('button.old_password')" path="oldPassword">
                    <NInput
                        v-model:value="model.oldPassword"
                        type="password"
                        show-password-on="mousedown"
                    />
                </NFormItem>

                <NFormItem m-t="4" :label="$t('button.new_password')" path="newPassword">
                    <NInput
                        v-model:value="model.newPassword"
                        type="password"
                        show-password-on="mousedown"
                    />
                </NFormItem>

                <NFormItem m-t="4" :label="$t('button.confirm_password')" path="confirmPassword">
                    <NInput
                        v-model:value="model.confirmPassword"
                        type="password"
                        show-password-on="mousedown"
                    />
                </NFormItem>
                <span
                    :style="{
                        'font-size': '12px',
                        color: 'red',
                        paddingLeft: '30px',
                    }"
                >
                    {{ $t('validation.cipher.cipher_rule_message') }}
                </span>
            </NForm>
        </NSpace>

        <template #footer>
            <NSpace justify="center">
                <!-- :disabled="!password" -->
                <NButton @click="confirm">
                    <div>{{ $t('button.go') }}</div>
                </NButton>
            </NSpace>
        </template>
    </NModal>
</template>

<script lang="ts" setup>
import { NButton, NInput, useMessage } from 'naive-ui';
import type { FormInst, FormRules } from 'naive-ui';
import { useRequests } from './request';

interface ModelType {
    oldPassword: any;
    newPassword: any;
    confirmPassword: any;
}
const props = defineProps<{
    show: boolean;
}>();

const emits = defineEmits(['update:close']);

const { modifyPwd } = useRequests();
const message = useMessage();
const { t, locale } = useTypedI18n();

const formRef = ref<FormInst | null>(null);

const model = ref<ModelType>({
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
});

const rules: FormRules = {
    oldPassword: [
        {
            required: true,
        },
    ],
    newPassword: [
        {
            required: true,
        },
    ],
    confirmPassword: [
        {
            required: true,
        },
    ],
};

const confirm = () => {
    // 调用表单的 validate 方法进行表单验证
    formRef.value?.validate((valid) => {
        if (!valid) {
            // 如果验证通过，则进行后续操作
            modifyPwd({
                oldPassword: model.value.oldPassword,
                newPassword: model.value.newPassword,
                confirmPassword: model.value.confirmPassword,
            }).then((res) => {
                if (res) {
                    model.value.oldPassword = null;
                    model.value.newPassword = null;
                    model.value.confirmPassword = null;
                    emits('update:close', false);
                }
            });
        } else {
            // 如果验证失败，则提示用户填写必填项
            // message.error('请填写必填项', { duration: 3000 });
            message.error(t('validation.common.required'), { duration: 3000 });
        }
    });
};

// const confirm = () => {
//     formRef.value?.validate((valid) => {
//         if (valid) {
//             modifyPwd({
//                 oldPassword: model.value.oldPassword,
//                 newPassword: model.value.newPassword,
//                 confirmPassword: model.value.confirmPassword,
//             }).then((res) => {
//                 if (res) {
//                     model.value.oldPassword = null;
//                     model.value.newPassword = null;
//                     model.value.confirmPassword = null;
//                     emits('update:close', false);
//                 }
//             });
//         }
//     });

// };
</script>

<style scoped></style>
