import type { DropdownOption } from 'naive-ui';
import { useTypedI18n } from './i18n';
import type { Locales } from './i18n';
import { useCommonStore } from '~/stores/common';
import type { UserLangListItem } from '~/stores/user';

const availableLocales = ref<UserLangListItem[]>([]);

export const useAvailableLocaleList = () => {
    return {
        availableLocales,
    };
};

export const useGlobalLanguage = () => {
    const commonStore = useCommonStore();
    const { t, locale } = useTypedI18n();
    const { availableLocales } = useAvailableLocaleList();

    const options = computed<DropdownOption[]>(() =>
        availableLocales.value.map((i) => {
            return {
                key: i.langCode,
                value: i.langCode,
                label: i.langName,
            };
        }),
    );

    const nameMapRef = computed(() => {
        const map = new Map<string, string>();
        availableLocales.value.forEach((i) => {
            map.set(i.langCode, i.langName);
        });
        return map;
    });

    const handleSelect = async (key: Locales, option: DropdownOption) => {
        locale.value = key;
        commonStore.locale = key;
    };

    return {
        t,
        options,
        handleSelect,
        locale,
        nameMapRef,
    };
};
