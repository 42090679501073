import zhCN from '../../locales/zh-CN';
import enUS from '../../locales/en-US';

export type MessageSchema = typeof enUS;

export enum Locales {
    enUS = 'en-US',
    zhCN = 'zh-CN',
    // deDE = 'de-DE',
    // frFR = 'fr-FR',
    // nlNL = 'nl-NL',
    // itIT = 'it-IT',
}

export const useTypedI18n = () => {
    return useI18n<[MessageSchema], Locales>();
};

const watchedRouteMap = new Map<string, unknown>();

export const useLocalI18n = (initial?: [locale: string, message: unknown][]) => {
    const route = useRoute();
    const path = route.path;
    const router = useRouter();
    const i18n = useI18n<[MessageSchema], Locales>({
        useScope: 'local',
        messages: {
            [Locales.zhCN]: zhCN,
            [Locales.enUS]: enUS,
        },
    });

    const locales = ref<Parameters<typeof i18n.mergeLocaleMessage>[]>(initial ?? []);

    if (!watchedRouteMap.has(path)) {
        watch(router.currentRoute, (v) => {
            if (v.path === path)
                locales.value.forEach((locale) => {
                    i18n.mergeLocaleMessage(...locale);
                });
        });
        watchedRouteMap.set(path, true);
    }

    watch(
        locales,
        () => {
            locales.value.forEach((locale) => {
                i18n.mergeLocaleMessage(...locale);
            });
        },
        {
            immediate: true,
        },
    );

    return {
        i18n,
        locales,
    };
};
