import type { MessageContext } from 'vue-i18n';
import homepage from './pages/homepage/index';

export default {
    license: {
        powered_by: 'Powered by SMIL',
    },
    auth: {
        login: 'Login',
        username: 'Username',
        username_placeholder: 'Enter username',
        password: 'Password',
        password_placeholder: 'Enter password',
        code: 'Verify Code',
        code_placeholder: 'Enter verify code',
        logout: 'Logout',
        logout_success: 'Logout success',
    },
    button: {
        editing_position: 'Editing position',
        query: 'Query',
        old_password: 'Old password',
        new_password: 'New password',
        confirm_password: 'Confirm Password',
        change_password: 'Change Password',
        reset_password: 'Reset Password',
        cipher: 'cipher',
        cipher_password: 'Cipher',
        change_new_password: 'Please fill in the new password',
        show_theme_editor: 'Show/Hide Theme Editor',
        button: 'Button',
        about: 'About',
        back: 'Back',
        go: 'GO',
        home: 'Home',
        toggle_dark: 'Toggle dark mode',
        toggle_langs: 'Change languages',
        confirm: 'Confirm',
        modify: 'Edit',
        edit: 'Edit',
        new: 'New',
        unlock: 'Unlock',
        cancel: 'Cancel',
        close: 'Closed',
        search: 'Search',
        reset: 'Reset',
        delete: 'Delete',
        add: 'Add',
        add_remark: 'Add Remark',
        collapse: 'Collapse',
        expand: 'More',
        download_template: 'Download Template',
        batch_import: 'Batch Import',
        import: 'Import',
        export: 'Export',
        export_fail: 'Export Fail',
        batch_clear_sap_code: 'Batch clear SAP code',
        batch_clear_special_purchase_cycle: 'Batch clear special purchase cycle',
        detailed: 'detailed',
        download: 'Download',
        toView: 'check',
        toViews: 'check',
        view: 'View',
        uploadAttachments: 'Upload',
        selectFile: 'Select File',
        fillingInstructions: 'Filling Instructions',
        release: 'Release',
        save: 'Save',
        send_back: 'Return',
        examine: 'examine',
        updateTime: 'Update validity period',
        update: 'update',
        void: 'void',
        more: 'More',
        refresh: 'Refresh',
        submit_sap: 'Submit SAP',
        submit: 'submit',
        save_button: 'Save and New',
        insufficient: 'Whether to submit if the account fund is insufficient',
        yes: 'yes',
        deny: 'deny',
        staging: 'Save and Close',
        return: 'Return',
        table: 'No.',
        select: 'Choose',
        selectAll: 'All',
        all: 'all',
        create_sap: 'createSap',
        enter: 'Please Input',
        selects: 'Please Select',
        please_search: 'Please click to search',
        modify_ladder: 'Modify ladder',
        pass: 'pass through',
        serial_number: 'No.',
        individual: 'individual',
        upload_progress: 'Upload Progress',
        port_choose: 'port choose',
        request_code: 'Please check accessories',
        request_discuss: 'Please select a supplier',
        request_dt: 'Please check DT',
        request_thing: 'Please check the material category',
        translate: 'Only letters',
        accessories_information: 'Accessories information',
        select_accessories: 'Select accessories',
        binding: 'binding',
        tick_data: 'Please check the data',
        invalid: 'Invalid',
        empty_data: 'Clear generated data',
        confirm_number: 'Confirm packing list number',
        variance_details: 'Variance Details',
        daily_record: 'daily record',
        operation_type: 'Operation type',
        field_name: 'Field Name',
        edit_role: 'Edit Role',
        user_role: 'User Role',
        assignment_role: 'Role',
        post: 'post',
        please_settled: 'Please select the part to be settled',
        not_sent: 'Not sent',
        sending: 'Sending',
        has_been_sent: 'has been sent',
        fail_in_send: 'fail in send',
        express_import: 'Express import',
        password_reset: ' password reset',
        reset_new_password: ' to 111111?',
        display_or_not: 'Display or not',
        export_dash_board: 'Export Dashboard',
        export_data: 'Export Data',
        add_subordinate: 'Add Subordinate',
        add_sibling: 'Add Sibling',
        add_root: 'Add RootNode',
        back_detail: 'Back Detail',
    },
    radio: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        catalogue: 'Catalogue',
        individual: 'Individual',
        all: 'All',
        tips: 'tips',
        save: 'save',
        yes: 'yes',
        no: 'no',
    },
    dropdown: {
        close_others: 'Close Others',
        close_right: 'Close Right',
        close_left: 'Close Left',
    },
    table: {
        action: 'Operation',
        explain: 'Explain',
        fileName: 'File Name',
        size: 'Size(MB)',
        uploader: 'Uploader',
        uploadTime: 'Upload Time',
        index: 'Index',
    },
    pagination: {
        pages: ({ named }: MessageContext) => `${named('number')}/page`,
        total: ({ named }: MessageContext) => `${named('number')} records in total`,
    },
    import: {
        template_file: 'Template file',
        download_template: 'Download template',
        no_file_selected: 'No file selected',
        browse: 'Browse',
    },
    downloadTemplateName: 'Data Filling Template.xlsx',
    confirm: {
        confirm_deletion: 'confirm deletion?',
        confirm_void: 'confirm void?',
        confirm_release: 'confirm release?',
        ack: 'confirm',
        cancel: 'cancel',
        non_records: 'Please choose at least one record',
    },
    request: {
        failed_placeholder: 'request failed, backend error',
        success_placeholder: 'request success',
        auth: {
            login_success: 'login success',
        },
        error: {
            '404': 'request url not found',
            '403': 'permission denied',
        },
        data_required: 'No data for the current month',
    },

    page: {
        global: {
            page_search: 'Page Search',
            currency_type: 'CurrencyType',
            rmb: 'RMB',
            original: 'Original',
            actual: 'Actual',
            budget: 'Budget',
            total: 'Total',
            year: 'year',
        },
        error: {
            not_found: 'Not found',
            not_found_des: 'There is nothing here',
            auth_failed: 'Auth Failed',
            auth_failed_des: 'This page is not reachable',
        },
        homepage: {
            homepage: homepage.homepage.enUS,
        },
        document: {
            document: 'Document',
        },
        data: {
            confirm: {
                current: {
                    // button_data_preservation: 'Data Preservation',
                    button_data_preservation: 'Save',
                    // button_to_submit: 'To Submit',
                    button_to_submit: 'Go to submit',
                    // button_confirm_submit: 'Confirm To Submit',
                    button_confirm_submit: 'Confirm submission',
                    // button_search: 'Copy Last Month\'s Data',
                    button_search: 'Copy data from last month',
                    // button_to_check: 'Check The Control Table',
                    button_to_check: 'View "Labor Cost Report"',
                    // button_add: 'Add Row',
                    button_add: 'Add a new line',
                },
                confirmation: {
                    button_back: 'Back',
                    button_confirm_to_submit: 'Confirm To Submit',
                },
                message: {
                    month_required: 'Month is reuqired',
                    remark_required: 'Remark is reuqired',
                },
            },
            labor_cost_detail: {
                date: 'Date',
                employee_code: 'Employee No.',
                dept_code_list: 'DeptCodeList',
                employee_no: 'Employee No.',
                name: 'Name',
                query_field: 'queryField',
                department: 'Department',
                level: 'Level',
                employee_type: 'Employee Type',
                company: 'Company',
                item: 'Item',
            },
            organizational_structure: {
                on_duty_time: 'On Duty Time',
                import_photo: 'Import Photo',
                add_photo: 'Add Photo',
                staff_amount: 'Staff Amount',
                on_duty_amount: 'On Duty Amount',
            },
        },
        system: {
            menu: {
                menu: 'Menu',
                menu_name: 'Menu name',
                menu_type: 'Menu Type',
                menu_new: 'New Menu',
                menu_edit: 'Modify Menu',
                icon: 'Icon',
                parent: 'Parent',
                order_number: 'Order Number',
                path: 'Path',
                status: 'Status',
            },
            dict: {
                dictionary_name: 'Dictionary Name',
                creater: 'Creater',
                dictionary_code: 'Dictionary Code',
                code_type: 'Code Type',
                code_type_name: 'Code Type Name',
                creation_time: 'Creation Time',
                dict_new: 'Dict New',
                dict_edit: 'Dict Edit',
                lang: 'Lang',
                sort: 'Sort',
            },
            dept: {
                system_selection: 'System selection',
                organizational_type: 'Organizational type',
                parent_dept: 'Parent dept',
                dept_name: 'Dept name',
                dept_code: 'Dept code',
                dept_type: 'Dept type',
                use_currency: 'Usecurrency',
                leader: 'Leader',
                email: 'Email',
                create_by: 'Create By',
                create_date: 'Create Date',
                organization_editing: 'Organization Editing',
                phone: 'Phone',
                superior_company: 'Superior Company',
                company_name: 'Company Name',
                company_code: 'Company Code',
                company_type: 'Company Type',
                currency: 'Currency',
                organizational_name: 'Organizational name',
                organizational_code: 'Organizational code',
                subsidiary: 'Subsidiary',
                subsidiary_name: 'Subsidiary Name',
                subsidiary_code: 'Subsidiary Code',
                add: 'add',
                subsidiary_required: 'Please select subsidiary',
            },
            role: {
                menu_maintenance: 'menu maintenance',
                authority_maintenance: 'authority maintenance',
                role_name: 'Role Name',
                role_key: 'Permission character',
                role_id: 'Display order',
                status: 'status',
                create_date: 'Creation time',
                role_sort: 'Sort',
                role_new: 'New Role',
                role_edit: 'Edit Role',
                role_bleak: 'Bleak',
                role_code: 'Rolw Code',
                permissions_maintenance: 'Permissions Maintenance',
                created_time: 'Created Time',
                isexpend: 'Expand or not',
            },
            user: {
                serial_no: 'No.',
                username: ' Username',
                name: 'Name',
                sex: 'gender',
                phone_number: 'Phone Number',
                email: 'email',
                role: 'Role',
                login_time: 'Enable Status',
                password: 'Password',
                user_edit: 'User Edit',
                organization: 'Organization',
                user_account: 'User Account',
                user_name: 'User Name',
                email_address: 'E-mail Address',
                status: 'Status',
                status_disable: 'Disable',
                status_enable: 'Enable',
                language: 'Language',
                password_reset: 'Password Reset',
                reset_message: 'Confirm to reset password as 111111?',
            },
            warning: {
                highlight_reminder_color: 'Highlights Settings',
            },
            maintenance: {
                expatriate_employee_job_id: 'Expatriate Employee Job ID',
                serial_no: 'No.',
                name: 'Name',
                assignment_time: 'Assignment Time',
                assignment_location: 'Assignment Location',
                actual_workplace: 'Actual Workplace',
                expatriate_time: 'Expatriate Time',
                expatriate_location: 'Expatriate Location',
                actual_working_place: 'Actual Working Place',
                employee_no: 'Employee No.',
                delete_message: 'Confirm to delete the expatriate information?',
            },
            rate: {
                rate_year_required: 'Year is required',
                rate_type_required: 'Rate Type is required',
                real_rate: 'Real Rate',
                budget_rate: 'Budget Rate',
                unit: 'CNY',
                year: 'Year',
                actual_exchange_rate: 'Actual Exchange Rate',
                forecast_exchange_rate: 'Forecast Exchange Rate',
                budget_exchange_rate: 'Budget Exchange Rate',
            },
            columns_seting: {
                card_title: 'Column Displays Setting',
                dept_name: 'Company',
                date: 'Date',
                dept_code: 'DeptCode',
                submission_date: 'Submission Date',
                card_title_new: 'Column Displays Setting for Subsidiary Labor Cost',
                company_name: 'Company Name',
                company_code: 'Company Code',
                submission_time: 'Submission Time',
            },
            dash_board: {
                report_caliber: 'Report Caliber',
                date: 'Date',
                company: 'Company',
                export_dashboard: 'Export Dashboard',
                finance_caliber: 'Finance Caliber',
                management_caliber: 'Management Caliber',
                operation_revenue: 'Operation Revenue',
                operating_income_per_capita: 'Operation Revenue/Average number of all employees',
                personnel_cost_rate: 'Total labor costs of all personnel/Operation Revenue',
                title_revenue: 'Revenue-related Labor Efficiency',
                total_profit: 'Profits Before Tax',
                profit_per_capita: 'Proftis Before Tax/Average number of all personnel',
                labor_cost_profit_margin: 'Profits Before Tax/Total Labor Cost of all employees',
                title_profit: 'Profits-related Labor Efficiency',
                title_labor_cost_of_month: 'Annual Labor Cost by Month',
                total_labor_cost_of_all_employees: 'Total Labor Costs of all employees',
                average_headcount_of_all_employees: 'Average headcount of all employees',
                sales_volume: 'Sales Volume',
                title_labor_cost_of_mutiple_year: 'Labor Costs of Mutiple Years',
                // title_per_capita_salart_overview: 'Per Capita Salart OverView',
                title_per_capita_salart_overview: 'Per capita "Wages & Salaries" Overview',
                title_annual_labor_cost_by_month: 'Annual Labor Cost by Month',
                title_proportion_of_position_types: 'Proportion of Position Types',
                proportion: 'proportion',
                // average_number: 'averageNumber',
                average_number: 'Per capita Wages',
                // per_capita: 'perCapita',
                per_capita: 'Average HC',
            },
        },
        audit: {
            audit_form: {
                deptName: 'Company',
                date: 'Date',
            },
            audit_table: {
                deptName: 'Company',
                deptCode: 'DeptCode',
                submission_date: 'Submission date',
                status: 'Status',
            },
        },
        budegt: {
            budegt_form: {
                dept_name: 'Company',
                date: 'Date',
                employee_type: 'EmployeeType',
                department: 'Department',
                level: 'Level',
            },
            budegt_table: {
                serial_number: 'No.',
                dept_name: 'Company',
                dept_code: 'DeptCode',
                date: 'Date',
                statue: 'Status',
            },
        },
    },
    validation: {
        common: {
            required: 'Item is required',
            numeric: 'Must be integer',
            decimal: 'Must be number',
            positive_interger: 'Must be positive interger',
            up_to_digits: ({ named }: MessageContext) => `Up to ${named('number')} digits`,
            more_than_characters: ({ named }: MessageContext) =>
                `More than ${named('number')} characters`,
            max_bytes: ({ named }: MessageContext) => `Exceed max bytes (${named('number')})`,
            less_than_characters: ({ named }: MessageContext) =>
                `Less than ${named('number')} characters`,
            must_be_characters: ({ named }: MessageContext) =>
                `Must be ${named('number')} characters`,
            greater_than: ({ named }: MessageContext) => `Greater than ${named('number')}`,
            less_than: ({ named }: MessageContext) => `Less than ${named('number')}`,
            up_to_2_decimal_places: 'Up to 2 decimal places',
            retain_up: 'Enter up to 10 positive integers and retain up to 4 decimal places',
            retain_ups: 'Enter up to 11 positive integers, and retain up to 4 decimal places',
            input_number: 'Please enter the number',
            only_number_a: 'Only letters or numbers can be entered',
            positive_integer: 'Enter at least one field',
            at_least_one: 'Fill in at least one',
        },
        tip: {
            incomplete_information: 'Incomplete Information',
            MG_information: 'MG orderer cannot be empty',
            MAXUS_information: 'MAXUS orderer cannot be empty',
        },
        cipher: {
            cipher_required: 'Cipher is required',
            cipher_rule_message:
                'The password must contain uppercase letters, lowercase letters, numbers, and special characters ({\'@\'}$!_~&*), and the length of the password should be between 8 to 16 characters.',
        },
    },
    export: {
        noDataToExport: 'No data to export',
    },
    select: {
        all: 'All',
    },
    checkbox: {
        select_all: 'Select All',
    },
    dialog: {
        error_message: 'error message',
        accessory_selection: 'Part selection',
    },
    data_table: {
        dept_name: 'Company',
        employee_information: 'Employee Information',
        total_wages: 'Total Wages',
        social_security: 'Social Security',
        rest_of_labor_cost: 'Rest of Labor Cost',
        contract_type: 'Contract Type',
        employee_type: 'Employee Type',
        employee_code: 'Employee Code',
        employee_no: 'Employee No.',
        unified_id: 'Unified ID',
        name: 'Name',
        postion_type: 'Postion Type',
        department: 'Department',
        level: 'Level',
        basic_salary: 'Basic Salary',
        allowance1: 'Allowance 1',
        allowance2: 'Allowance 2',
        allowance3: 'Allowance 3',
        allowance4: 'Allowance 4',
        allowance5: 'Allowance 5',
        year_end_bonus: 'Annual bonus',
        commission: 'Commission',
        bonus: 'Other bonus',
        employer_social_security1: 'Employer Social Security 1',
        employer_social_security2: 'Employer Social Security 2',
        employer_social_security3: 'Employer Social Security 3',
        employer_social_security4: 'Employer Social Security 4',
        employer_social_security5: 'Employer Social Security 5',
        employer_social_security6: 'Employer Social Security 6',
        // basic_accumulation_fund: 'Basic accumulation fund',
        basic_accumulation_fund: 'Basic Housing Provident Fund',
        // supplementary_accumulation_fund: 'Supplementary accumulation fund',
        supplementary_accumulation_fund: 'Supplementary Housing Provident Fund',
        enterprise_annuity: 'Enterprise annuity',
        supplementary_medical_insurance: 'Supplementary medical insurance',
        // insurance: 'Insurance',
        insurance: 'Commercial Insurance',
        welfare_fee: 'Welfare Fee',
        training_fee: 'Training Fee',
        // labor: 'Labor',
        labor: 'Labor Protection Fee',
        labor_union_expenditures: 'Labor union expenditures',
        compensation: 'Compensation',
        other1: 'Other1',
        other2: 'Other2',
        other3: 'Other3',
        sub_total: 'Sub-Total',
        remark: 'Remark',
        indicators: 'Indicators',
        the_units: 'The Units',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
        total: 'Total Actual',
        total_budget: 'Total Budget',
        yoy1: 'YOY',
        optimized_total_budget: 'Optimized Total Budget',
        yoy2: 'YOY',
        message: {
            wrong_format: 'Wrong Format(exp:ABC12345)',
        },
        deptcode: 'DeptCode',
        deptname: 'Company',
        optimization_requirements: 'Optimization Requirements',
        dialog: {
            title: 'Add Remark',
            formItem_title: 'Remark',
        },
        cover_dialog: {
            title: 'In the selected month, there exists data filled before. Confirm to overwrite?',
        },
        columns_title: {
            CONTRACT_TYPE: 'Contract Type',
            EMPLOYEE_TYPE: 'Employee Type',
            EMPLOYEE_NO: 'Employee No.',
            NAME: 'Name',
            POSTION_TYPE: 'Postion Type',
            DEPARTMENT: 'Department',
            LEVEL: 'Level',
            BASIC_SALARY: 'Basic Salary',
            ALLOWANCE1: 'Allowance 1',
            ALLOWANCE2: 'Allowance 2',
            ALLOWANCE3: 'Allowance 3',
            ALLOWANCE4: 'Allowance 4',
            ALLOWANCE5: 'Allowance 5',
            YEAR_END_BONUS: 'Annual bonus',
            COMMISSION: 'commission',
            BONUS: 'Other bonus',
            EMPLOYER_SOCIAL_SECURITY1: 'Employer Social Security 1',
            EMPLOYER_SOCIAL_SECURITY2: 'Employer Social Security 2',
            EMPLOYER_SOCIAL_SECURITY3: 'Employer Social Security 3',
            EMPLOYER_SOCIAL_SECURITY4: 'Employer Social Security 4',
            EMPLOYER_SOCIAL_SECURITY5: 'Employer Social Security 5',
            EMPLOYER_SOCIAL_SECURITY6: 'Employer Social Security 6',
            // BASIC_ACCUMULATION_FUND: 'Basic Housing Provident Fund',
            BASIC_ACCUMULATION_FUND: 'Basic Housing Provident Fund',
            // SUPPLEMENTARY_ACCUMULATION_FUND: 'Supplementary Housing Provident Fund',
            SUPPLEMENTARY_ACCUMULATION_FUND: 'Supplementary Housing Provident Fund',
            ENTERPRISE_ANNUITY: 'Enterprise Annuity',
            SUPPLEMENTARY_MEDICAL_INSURANCE: 'Supplementary Medical Insurance',
            INSURANCE: 'Commercial Insurance',
            WELFARE_FEE: 'Welfare Fee',
            TRAINING_FEE: 'Training Fee',
            // LABOR_PROTECTION_FEE: 'Labor protection fee',
            LABOR_PROTECTION_FEE: 'Labor Protection Fee',
            LABOR_UNION_EXPENDITURES: 'Labor union expenditures',
            COMPENSATION: 'Compensation',
            OTHERS1: 'Others 1',
            OTHERS2: 'Others 2',
            OTHERS3: 'Others 3',
            SUB_TOTAL: 'Sub-Total',
            NOTES: 'Remarks',
        },
        unit: {
            unit_CNY: 'CNY',
            unit_10KCNY: '10KCNY',
            unit_PERSON: 'PERSON',
            unit: 'UNIT',
        },
        status: 'Status',
        unlocked: 'unlocked',
        lock: 'locked',
        draft: 'draft',
        committed: 'committed',
        optimizing: 'optimizing',
        optimized: 'optimized',
    },
    data: {
        search_condition: {
            month: 'Month',
            year: 'Query Year',
        },
        upload_message: {
            only_supports: 'Only excel files in xls and xlsx format can be uploaded',
        },
        confirm_message: {
            // // 继续确认提交
            // confirm_title: 'Please confirm that all information is correct, whether to confirm to submit',
            // // 继续前往提交
            // submit_title:
            //     'Please confirm that all information is correct, whether to continue to submit',
            // 继续确认提交
            confirm_title: 'Please confirm all data correct before submitting？',
            // 继续前往提交
            submit_title: 'Please confirm all data correct before submitting？',
            submit_check:
                'Please right-click on the highlighting data and fill the remarks before submitting',
        },
        table_vaildate_message: {
            employeecode_requried: 'EmployeeCode is required',
            employeeno_requried: 'EmployeeNo is required',
            deptcode_requried: 'DeptCode is required',
            employeeno_nosame: 'Line {index} - "Employee No." can not be repeat.',
            dataFill_required: 'Don\'t submit empty data',
        },
    },
    components: {
        twin_date_picker: {
            start_message: 'From',
            end_message: 'To',
        },
    },
};
