import type { MessageContext } from 'vue-i18n';
import homepage from './pages/homepage/index';

export default {
    license: {
        powered_by: '上汽国际 {\'|\'} 版权所有',
    },
    auth: {
        login: '登录',
        username: '用户名',
        username_placeholder: '请输入用户名',
        password: '密码',
        password_placeholder: '请输入密码',
        code: '验证码',
        code_placeholder: '请输入验证码',
        logout: '注销',
        logout_success: '注销成功',
    },
    button: {
        query: '查询',
        editing_position: '编辑岗位',
        show_theme_editor: '显示/隐藏主题编辑器',
        change_password: '修改密码',
        reset_password: '重置密码',
        old_password: '旧密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        cipher: '密码',
        cipher_password: '密码',
        change_new_password: '请填写新密码',
        button: '按钮',
        about: '关于',
        back: '返回',
        go: '确定',
        home: '首页',
        toggle_dark: '切换深色模式',
        toggle_langs: '切换语言',
        confirm: '确定',
        modify: '修改',
        edit: '编辑',
        new: '新增',
        unlock: '解锁',
        cancel: '取消',
        close: '关闭',
        search: '搜索',
        reset: '重置',
        delete: '删除',
        add: '新增',
        add_remark: '新增备注',
        collapse: '收起',
        expand: '展开',
        download_template: '下载模板',
        batch_import: '批量导入',
        import: '导入',
        export: '导出',
        export_fail: '导入失败',
        batch_clear_sap_code: '批量清空SAP Code',
        batch_clear_special_purchase_cycle: '批量清空特殊采购周期',
        detailed: '明细',
        download: '下载',
        toView: '查看',
        toViews: '查看图片',
        view: '查看',
        uploadAttachments: '上传附件',
        selectFile: '选择文件',
        fillingInstructions: '填写说明',
        release: '发布',
        save: '保存',
        tips: '提示',
        send_back: '退回',
        examine: '审核',
        create_sap: '创建SAP',
        update: '更新',
        updateTime: '更新有效期',
        ilterOptionsupdate: '更新',
        void: '作废',
        more: '更多',
        refresh: '刷新',
        submit_sap: '提交SAP',
        submit: '提交',
        save_button: '保存并新建',
        insufficient: '账户资金不足是否提交',
        yes: '是',
        deny: '否',
        staging: '暂存',
        return: '退回',
        table: '序号',
        select: '选择',
        selectAll: '全选',
        all: '共',
        enter: '请输入',
        selects: '请选择',
        please_search: '请点击查询',
        modify_ladder: '修改阶梯',
        pass: '通过',
        serial_number: '序号',
        individual: '个',
        upload_progress: '上传进度',
        port_choose: '港口选择',
        request_code: '请勾选配件',
        request_discuss: '请选择供应商',
        request_dt: '请勾选DT',
        request_thing: '请勾选物料小类',
        translate: 'Only letters',
        accessories_information: '配件信息',
        select_accessories: '选择配件',
        binding: '绑定',
        tick_data: '请勾选数据',
        invalid: '作废',
        confirm_number: '确认箱单号:',
        empty_data: '清空生成数据',
        variance_details: '差异明细',
        daily_record: '日志',
        operation_type: '操作类型',
        field_name: '字段名',
        edit_role: '编辑角色',
        user_role: '选择角色',
        assignment_role: '角色',
        post: '岗位',
        please_settled: '请选择要结算的配件',
        not_sent: '未发送',
        sending: '发送中',
        has_been_sent: '已发送',
        fail_in_send: '发送失败',
        express_import: '快递导入',
        password_reset: '密码重置',
        reset_new_password: '为111111?',
        display_or_not: '是否显示',
        export_dash_board: '导出仪表盘',
        export_data: '导出数据',
        add_subordinate: '添加下级',
        add_sibling: '添加同级',
        add_root: '添加根节点',
        back_detail: '返回明细',
    },
    radio: {
        enabled: '启用',
        disabled: '停用',
        catalogue: '目录',
        individual: '个体',
        all: '全部',
        tips: '标签',
        save: '保存',
        yes: '是',
        no: '否',
    },
    dropdown: {
        close_others: '关闭其他',
        close_right: '关闭右侧',
        close_left: '关闭左侧',
    },
    table: {
        action: '操作',
        explain: '说明',
        fileName: '文件名',
        size: '大小(MB)',
        uploader: '上传人',
        uploadTime: '上传时间',
        index: '序号',
    },
    pagination: {
        pages: ({ named }: MessageContext) => `${named('number')}/页`,
        total: ({ named }: MessageContext) => `共 ${named('number')} 条数据`,
    },
    import: {
        template_file: '模板文件',
        download_template: '下载模板',
        no_file_selected: '未选择文件',
        browse: '浏览',
    },
    downloadTemplateName: '数据填报模板.xlsx',
    confirm: {
        confirm_deletion: '确认删除?',
        confirm_void: '确认作废?',
        confirm_release: '确认发布?',
        ack: '确认',
        cancel: '取消',
        non_records: '请至少选择一条数据',
    },
    request: {
        failed_placeholder: '请求失败，服务端错误',
        success_placeholder: '请求成功',
        auth: {
            login_success: '登录成功',
        },
        error: {
            '404': '请求路径不存在',
            '403': '权限不足',
        },
        data_required: '当月无数据',
    },
    page: {
        global: {
            page_search: '页面搜索',
            currency_type: '货币单位',
            rmb: '人民币',
            original: '原币',
            actual: '实际/预测数据',
            budget: '预算数据',
            total: '总计',
            year: '年',
        },
        error: {
            not_found: '未找到页面',
            not_found_des: '这里没有任何东西',
            auth_failed: '权限不足',
            auth_failed_des: '此页面禁止访问',
        },
        homepage: {
            homepage: homepage.homepage.zhCN,
        },
        document: {
            document: '文档',
        },
        data: {
            confirm: {
                current: {
                    button_data_preservation: '数据保存',
                    button_to_submit: '前往提交',
                    button_confirm_submit: '确认提交',
                    button_search: '复制上月数据',
                    button_to_check: '查看管控表',
                    button_add: '添加行',
                },
                confirmation: {
                    button_back: '返回',
                    button_confirm_to_submit: '确认提交',
                },
                message: {
                    month_required: '月份必填',
                    remark_required: '备注必填',
                },
            },
            labor_cost_detail: {
                date: '日期',
                employee_code: '统一工号',
                dept_code_list: '公司名称',
                employee_no: '工号',
                name: '姓名',
                query_field: '字段选择',
                department: '部门',
                level: '职级',
                employee_type: '人员类别',
                company: '公司名称',
                item: '指标',
            },
            organizational_structure: {
                on_duty_time: '在岗时间',
                import_photo: '导入照片',
                add_photo: '添加照片',
                staff_amount: '编制人数',
                on_duty_amount: '在岗人数',
            },
        },
        system: {
            menu: {
                menu: '菜单',
                menu_name: '菜单名称',
                menu_type: '菜单类型',
                menu_new: '菜单新增',
                menu_edit: '菜单编辑',
                icon: '图标',
                parent: '父级菜单',
                order_number: '排序',
                path: '路由地址',
                status: '菜单状态',
            },
            dict: {
                dictionary_name: '字典名称',
                creater: '创建人',
                dictionary_code: '字典代码',
                code_type: '类型代码',
                code_type_name: '类型名称',
                creation_time: '创建时间',
                dict_new: '字典新增',
                dict_edit: '字典编辑',
                lang: '语言',
                sort: '排序',
            },
            dept: {
                system_selection: '系统选择',
                parent_dept: '上级部门',
                dept_name: '组织名称',
                dept_code: '组织代码',
                dept_type: '公司级别',
                use_currency: '使用币种',
                leader: '负责人',
                email: '邮箱',
                create_by: '创建人',
                create_date: '创建时间',
                organization_editing: '组织编辑',
                phone: '电话',
                superior_company: '上级公司',
                organizational_type: '组织类型',
                company_name: '公司名称',
                company_code: '公司代码',
                company_type: '公司类型',
                currency: '使用币种',
                organizational_name: '组织名称',
                organizational_code: '组织代码',
                subsidiary: '下属公司',
                subsidiary_name: '下属公司名称',
                subsidiary_code: '下属公司代码',
                add: '添加',
                subsidiary_required: '请选择下属公司',
            },
            role: {
                menu_maintenance: '菜单维护',
                authority_maintenance: '权限维护',
                role_name: '角色名称',
                role_key: '角色代码',
                role_id: '显示顺序',
                status: '状态',
                create_date: '创建时间',
                role_sort: '排序',
                role_new: '角色新增',
                role_edit: '角色编辑',
                role_bleak: '菜单',
                role_code: '角色代码',
                permissions_maintenance: '权限维护',
                created_time: '创建时间',
                isexpend: '是否展开',
            },
            user: {
                serial_no: '序号',
                username: ' 用户名',
                name: '姓名',
                sex: '性别',
                phone_number: '手机号',
                email: '邮箱',
                role: '角色',
                login_time: '启用状态',
                password: '密码',
                user_edit: '用户编辑',
                organization: '组织',
                user_account: '用户账号',
                user_name: '用户姓名',
                email_address: '邮箱',
                status: '启用状态',
                status_disable: '禁用',
                status_enable: '启用',
                language: '语言',
                password_reset: '密码重置',
                reset_message: '确认密码重置为111111?',
            },
            warning: {
                highlight_reminder_color: '高亮提示颜色设置',
            },
            maintenance: {
                expatriate_employee_job_id: '外派员工工号',
                serial_no: '序号',
                name: '姓名',
                assignment_time: '外派时间',
                assignment_location: '外派地点',
                actual_workplace: '实际工作地',
                expatriate_time: '外派时间',
                expatriate_location: '外派地点',
                actual_working_place: '实际工作地',
                employee_no: '统一工号',
                delete_message: '是否删除该外派员工信息?',
            },
            rate: {
                rate_year_required: '请选择查询年份',
                rate_type_required: '请选择汇率类型',
                real_rate: '实际汇率',
                budget_rate: '预算汇率',
                unit: '人民币',
                year: '年份',
                actual_exchange_rate: '实际汇率',
                forecast_exchange_rate: '预测汇率',
                budget_exchange_rate: '预算汇率',
            },
            columns_seting: {
                card_title: '显示数据列配置',
                dept_name: '公司名称',
                date: '日期',
                dept_code: '公司代码',
                submission_date: '提交时间',
                card_title_new: '子公司人工成本明细表字段显示',
                company_name: '公司名称',
                company_code: '公司代码',
                submission_time: '提交时间',
            },
            dash_board: {
                report_caliber: '报表口径',
                date: '日期',
                company: '公司',
                export_dashboard: '导出仪表盘',
                finance_caliber: '财务支付口径',
                management_caliber: '管理口径',
                operation_revenue: '营业收入',
                operating_income_per_capita: '人均营业收入',
                personnel_cost_rate: '人事费用率',
                title_revenue: '收入相关人效指标',
                total_profit: '利润总额',
                profit_per_capita: '人均创利',
                labor_cost_profit_margin: '人工成本利润率',
                title_profit: '利润相关人效指标',
                title_labor_cost_of_month: '单年各月人工成本',
                total_labor_cost_of_all_employees: '全部人员人工成本总额',
                average_headcount_of_all_employees: '全部人员平均人数',
                sales_volume: '销量',
                title_labor_cost_of_mutiple_year: '各年度人工成本情况',
                title_per_capita_salart_overview: '人均工资概览',
                title_annual_labor_cost_by_month: '单年各月人工成本',
                title_proportion_of_position_types: '各年职能占比',
                proportion: '占比',
                average_number: '人均工资',
                per_capita: '平均人数',
            },
        },
        audit: {
            audit_form: {
                deptName: '公司名称',
                date: '日期',
            },
            audit_table: {
                deptName: '公司名称',
                deptCode: '公司代码',
                submission_date: '提交日期',
                status: '状态',
            },
        },
        budegt: {
            budegt_form: {
                dept_name: '公司名称',
                date: '日期',
                employee_type: '人员类别',
                department: '部门',
                level: '职级',
            },
            budegt_table: {
                serial_number: '序号',
                dept_name: '公司名称',
                dept_code: '公司代码',
                date: '提交时间',
                statue: '状态',
            },
        },
    },
    validation: {
        common: {
            required: '此项必填',
            numeric: '必须为整数数字',
            decimal: '必须为数字',
            positive_interger: '必须为正整数',
            up_to_digits: ({ named }: MessageContext) => `最多为${named('number')}位数`,
            more_than_characters: ({ named }: MessageContext) => `不能超过${named('number')}字`,
            max_bytes: ({ named }: MessageContext) => `超过最大字符数(${named('number')})`,
            less_than_characters: ({ named }: MessageContext) => `不少于${named('number')}字`,
            must_be_characters: ({ named }: MessageContext) => `必须为${named('number')}字`,
            greater_than: ({ named }: MessageContext) => `不能大于${named('number')}`,
            less_than: ({ named }: MessageContext) => `不能小于${named('number')}`,
            up_to_2_decimal_places: '最多为2位小数',
            retain_up: '最多输入10位正整数，最多保留2位小数',
            retain_ups: '最多输入11位正整数，最多保留4位小数',
            input_number: '请输入数字',
            only_number_a: '只能输入字母或数字',
            translate: '只能为字母',
            positive_integer: '至少输入一个字段',
            at_least_one: '至少填写一个',
        },
        tip: {
            incomplete_information: '信息未填写完整',
            MG_information: 'MG订单员不能为空',
            MAXUS_information: 'MAXUS订单员不能为空',
        },
        cipher: {
            cipher_required: '密码不能为空',
            cipher_rule_message:
                '密码必须包含大写字母,小写字母、数字、特殊符号（{\'@\'}$!_~&*），密码长度在 8-16 位',
        },
    },
    export: {
        noDataToExport: '没有可导出数据',
    },
    select: {
        all: '全部',
    },
    checkbox: {
        select_all: '全选',
    },
    dialog: {
        error_message: '错误信息',
        accessory_selection: '配件选择',
    },
    data_table: {
        employee_information: '员工信息',
        dept_name: '公司名称',
        total_wages: '工资总额',
        social_security: '社会保险',
        rest_of_labor_cost: '其余人工成本',
        contract_type: '劳动关系类别',
        employee_type: '人员类别',
        employee_no: '工号',
        employee_code: '统一工号',
        unified_id: 'Unified ID',
        name: '姓名',
        postion_type: '职能类别',
        department: '部门',
        level: '职级',
        basic_salary: '岗位工资',
        allowance1: '津贴1',
        allowance2: '津贴2',
        allowance3: '津贴3',
        allowance4: '津贴4',
        allowance5: '津贴5',
        year_end_bonus: '年终奖',
        commission: '提成',
        bonus: '其他奖金',
        employer_social_security1: '雇主承担的社会保险1',
        employer_social_security2: '雇主承担的社会保险2',
        employer_social_security3: '雇主承担的社会保险3',
        employer_social_security4: '雇主承担的社会保险4',
        employer_social_security5: '雇主承担的社会保险5',
        employer_social_security6: '雇主承担的社会保险6',
        basic_accumulation_fund: '基本公积金',
        supplementary_accumulation_fund: '补充公积金',
        enterprise_annuity: '企业年金',
        supplementary_medical_insurance: '补充医疗保险',
        insurance: '商业保险',
        welfare_fee: '福利费',
        training_fee: '教育培训费',
        labor: '劳动保护费',
        labor_union_expenditures: '工会经费',
        compensation: '经济补偿金',
        other1: '其他1',
        other2: '其他2',
        other3: '其他3',
        sub_total: '合计',
        remark: '备注',
        indicators: '指标',
        the_units: '单位',
        january: '1月',
        february: '2月',
        march: '3月',
        april: '4月',
        may: '5月',
        june: '6月',
        july: '7月',
        august: '8月',
        september: '9月',
        october: '10月',
        november: '11月',
        december: '12月',
        total: '年实际合计',
        total_budget: '年预算合计',
        yoy1: '同比',
        optimized_total_budget: '年优化预算合计',
        yoy2: '同比',
        message: {
            wrong_format: '格式错误(exp:ABC12345)',
        },
        deptcode: '组织代码',
        deptname: '组织名称',
        optimization_requirements: '优化要求',
        dialog: {
            title: '添加备注',
            formItem_title: '备注',
        },
        cover_dialog: {
            title: '选择的月份有已填报的数据，是否覆盖?',
        },
        columns_title: {
            CONTRACT_TYPE: '劳动关系类别',
            EMPLOYEE_TYPE: '人员类别',
            EMPLOYEE_NO: '工号',
            NAME: '姓名',
            POSTION_TYPE: '职能类别',
            DEPARTMENT: '部门',
            LEVEL: '职级',
            BASIC_SALARY: '岗位工资',
            ALLOWANCE1: '津贴1',
            ALLOWANCE2: '津贴2',
            ALLOWANCE3: '津贴3',
            ALLOWANCE4: '津贴4',
            ALLOWANCE5: '津贴5',
            YEAR_END_BONUS: '年终奖',
            COMMISSION: '提成',
            BONUS: '奖金',
            EMPLOYER_SOCIAL_SECURITY1: '雇主承担的社会保险1',
            EMPLOYER_SOCIAL_SECURITY2: '雇主承担的社会保险2',
            EMPLOYER_SOCIAL_SECURITY3: '雇主承担的社会保险3',
            EMPLOYER_SOCIAL_SECURITY4: '雇主承担的社会保险4',
            EMPLOYER_SOCIAL_SECURITY5: '雇主承担的社会保险5',
            EMPLOYER_SOCIAL_SECURITY6: '雇主承担的社会保险6',
            BASIC_ACCUMULATION_FUND: '基本公积金',
            SUPPLEMENTARY_ACCUMULATION_FUND: '补充公积金',
            ENTERPRISE_ANNUITY: '企业年金',
            SUPPLEMENTARY_MEDICAL_INSURANCE: '补充医疗保险',
            INSURANCE: '商业保险',
            WELFARE_FEE: '福利费',
            TRAINING_FEE: '教育培训费',
            LABOR_PROTECTION_FEE: '劳动保护费',
            LABOR_UNION_EXPENDITURES: '工会经费',
            COMPENSATION: '经济补偿金',
            OTHERS1: '其他1',
            OTHERS2: '其他2',
            OTHERS3: '其他3',
            SUB_TOTAL: '合计',
            NOTES: '备注',
        },
        unit: {
            unit_CNY: '元',
            unit_10KCNY: '万元',
            unit_PERSON: '人',
            unit: '台',
        },
        status: '状态',
        unlocked: '已解锁',
        lock: '已锁定',
        draft: '草稿',
        committed: '已提交',
        optimizing: '优化中',
        optimized: '已优化',
    },
    data: {
        search_condition: {
            month: '填写月份',
            year: '年份',
        },
        upload_message: {
            only_supports: '只能上传xls,xlsx格式的excel文件',
        },
        confirm_message: {
            // confirm_title: '请确认所有信息正确无误,是否继续确认提交',
            // submit_title: '请确认所有信息正确无误,是否继续前往提交',
            confirm_title: '提交前请确认所有数据正确无误',
            submit_title: '提交前请确认所有数据正确无误',
            submit_check: '请右键点击高亮数据单元格，输入备注后再提交',
        },
        table_vaildate_message: {
            employeecode_requried: '统一工号不能为空',
            employeeno_requried: '工号不能为空',
            deptcode_requried: '组织代码不能为空',
            employeeno_nosame: '第{index}行工号重复',
            dataFill_required: ' 请勿提交空数据',
            digit_check: '数字不合规（整数20位，小数6位）',
        },
    },
    components: {
        twin_date_picker: {
            start_message: '开始时间',
            end_message: '结束时间',
        },
    },
};
