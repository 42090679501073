<template>
    <n-config-provider
        :date-locale="localeObj[localeStr].date"
        :locale="localeObj[localeStr].lang"
        :theme-overrides="theme"
    >
        <n-dialog-provider>
            <n-notification-provider :placement="placement">
                <n-message-provider>
                    <main font-sans text="gray-700 dark:gray-200">
                        <component
                            :is="
                                showThemeEditor && localFlagShowThemeEditor
                                    ? NThemeEditor
                                    : 'div-container'
                            "
                        >
                            <n-loading-bar-provider>
                                <n-message-provider>
                                    <n-dialog-provider>
                                        <router-view />
                                    </n-dialog-provider>
                                </n-message-provider>
                            </n-loading-bar-provider>
                        </component>
                    </main>
                </n-message-provider>
            </n-notification-provider>
        </n-dialog-provider>
    </n-config-provider>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue';
import {
    NThemeEditor,
    // dateDeDE,
    dateEnUS,
    // dateFrFR,
    // dateItIT,
    // dateNlNL,
    dateZhCN,
    // deDE,
    enUS,
    // frFR,
    // itIT,
    // nlNL,
    zhCN,
} from 'naive-ui';
import theme from './styles/naive-ui-theme-overrides.json';
import { useThemeditorVisible } from './composables/useThemeEditor';
import { Locales } from './composables/i18n';

const { locale } = useTypedI18n();
const localeStr = locale as Ref<Locales>;
const localeObj = shallowRef({
    [Locales.zhCN]: {
        lang: zhCN,
        date: dateZhCN,
    },
    [Locales.enUS]: {
        lang: enUS,
        date: dateEnUS,
    },
    // [Locales.deDE]: {
    //     lang: deDE,
    //     date: dateDeDE,
    // },
    // [Locales.frFR]: {
    //     lang: frFR,
    //     date: dateFrFR,
    // },
    // [Locales.nlNL]: {
    //     lang: nlNL,
    //     date: dateNlNL,
    // },
    // [Locales.itIT]: {
    //     lang: itIT,
    //     date: dateItIT,
    // },
});

const { localFlagShowThemeEditor, showThemeEditor } = useThemeditorVisible();

const { placement } = useAppNotification();
</script>

<style scoped></style>
