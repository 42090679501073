export default {
    zhCN: {
        sales_order: '销售单',
        pack_no: '箱单号',
        pack_status: '箱单状态',
        history: '历史',
        order_confirm: '订单确认',
        order_submit: '订单提交',
        order_cancel: '订单取消',
        order_reviewed_today: '今日已审核订单',
        anji_logistics: '安吉物流',
        fund_review: '资金审核',
        pack: '箱单包装',
        bo_cancel: 'BO取消',
        sale_orders_query: '销售单查询',
        notice_history_query: '通知历史查询',
        is_read: '已读/未读',
        is_read_y: '已读',
        is_read_n: '未读',
        notice: '通知',
        notice_type: '通知类型',
        notice_time: '通知日期',
        notice_msg: '通知消息',
        operation_change_review: '改运审核',
        review_returned: '审核退回',
        bo_cancel_to_review: 'BO取消待审核',
        data_filling: '数据填报',
        data_query: '数据查询',
        report_query: '报表查询',
        dash_board: '仪表盘',
        labor_cost_review: '数据审核',

    },
    enUS: {
        sales_order: 'Sales Order',
        pack_no: 'Pack No',
        pack_status: 'Pack Status',
        history: 'History',
        order_confirm: 'Order Confirm',
        order_submit: 'Order Submit',
        order_cancel: 'Order Cancel',
        order_reviewed_today: 'Order Reviewed Today',
        anji_logistics: 'Anji Logistics',
        fund_review: 'Fund Review',
        pack: 'Pack',
        bo_cancel: 'BO Cancel',
        sale_orders_query: 'Sales Orders Query',
        notice_history_query: 'Notice History Query',
        is_read: 'Read/Unread',
        is_read_y: 'Read',
        is_read_n: 'Unread',
        notice: 'Notice',
        notice_type: 'Notice Type',
        notice_time: 'Notice Time',
        notice_msg: 'Notice Message',
        operation_change_review: 'Operation Change Review',
        review_returned: 'Review Returned',
        bo_cancel_to_review: 'BO Cancel To Review',
        // data_filling: 'Data Filling',
        data_filling: 'Labor Cost Filling',
        // data_query: 'Data Query',
        data_query: 'Labor Cost Details',
        // report_query: 'Report Query',
        report_query: 'Labor Cost Reports',
        dash_board: 'Dash Board',
        labor_cost_review: 'Labor Cost Review',

    },
    deDE: {
        sales_order: 'Verkaufsauftrag',
        pack_no: 'Packnummer',
        pack_status: 'Packstatus',
        history: 'Geschichte',
        order_confirm: 'Bestellung bestätigen',
        order_submit: 'Bestellung abschließen',
        order_cancel: 'Bestellung stornieren',
        order_reviewed_today: 'Heute überprüfte Bestellungen',
        anji_logistics: 'Anji Logistik',
        fund_review: 'Fondsprüfung',
        pack: 'Packen',
        bo_cancel: 'BO-Stornierung',
        sale_orders_query: 'Abfrage der Verkaufsaufträge',
        notice_history_query: 'Abfrage des Verlaufes der Benachrichtigungen',
        is_read: 'Gelesen/Ungelesen',
        is_read_y: 'Gelesen',
        is_read_n: 'Ungelesen',
        notice: 'Notiz',
        notice_type: 'Benachrichtigungstyp',
        notice_time: 'Benachrichtigungszeit',
        notice_msg: 'Benachrichtigungsnachricht',
        operation_change_review: 'Überprüfung der Betriebsänderung',
        review_returned: 'Überprüfung zurückgesandt',
        bo_cancel_to_review: 'BO-Stornierung zur Überprüfung',
    },
    frFR: {
        sales_order: 'Sales Order',
        pack_no: 'Pack No',
        pack_status: 'Pack Status',
        history: 'History',
        order_confirm: 'Order Confirm',
        order_submit: 'Order Submit',
        order_cancel: 'Order Cancel',
        order_reviewed_today: 'Order Reviewed Today',
        anji_logistics: 'Anji Logistics',
        fund_review: 'Fund Review',
        pack: 'Pack',
        bo_cancel: 'BO Cancel',
        sale_orders_query: 'Sales Orders Query',
        notice_history_query: 'Notice History Query',
        is_read: 'Read/Unread',
        is_read_y: 'Read',
        is_read_n: 'Unread',
        notice: 'Notice',
        notice_type: 'Notice Type',
        notice_time: 'Notice Time',
        notice_msg: 'Notice Message',
        operation_change_review: 'Operation Change Review',
        review_returned: 'Review Returned',
        bo_cancel_to_review: 'BO Cancel To Review',
    },
    nlNL: {
        sales_order: 'Verkooporder',
        pack_no: 'Pakketnummer',
        pack_status: 'Pakketstatus',
        history: 'Geschiedenis',
        order_confirm: 'Order bevestigen',
        order_submit: 'Order indienen',
        order_cancel: 'Order annuleren',
        order_reviewed_today: 'Order beoordeeld vandaag',
        anji_logistics: 'Anji Logistiek',
        fund_review: 'Fondsherziening',
        pack: 'Pakket',
        bo_cancel: 'BO Annuleren',
        sale_orders_query: 'Query Verkooporders',
        notice_history_query: 'Query Kondig Geschiedenis',
        is_read: 'Lezen / Ongelezen',
        is_read_y: 'Gelezen',
        is_read_n: 'Ongelezen',
        notice: 'Kennisgeving',
        notice_type: 'Type Kennisgeving',
        notice_time: 'Kennisgeving Tijd',
        notice_msg: 'Kennisgeving Bericht',
        operation_change_review: 'Wijzigingsbeoordeling',
        review_returned: 'Beoordeling Teruggestuurd',
        bo_cancel_to_review: 'BO Annuleren naar Beoordeling',
    },
    itIT: {
        sales_order: 'Ordine di Vendita',
        pack_no: 'Numero del Pacco',
        pack_status: 'Stato del Pacco',
        history: 'Storia',
        order_confirm: 'Conferma dell’Ordine',
        order_submit: 'Invio dell’Ordine',
        order_cancel: 'Annulla Ordine',
        order_reviewed_today: 'Ordini Recensiti Oggi',
        anji_logistics: 'Logistica Anji',
        fund_review: 'Revisione Fondi',
        pack: 'Pacco Imballaggio',
        bo_cancel: 'Annullamento BO',
        sale_orders_query: 'Query Ordini di Vendita',
        notice_history_query: 'Query Storia delle Notifiche',
        is_read: 'Letto/Non Letto',
        is_read_y: 'Letto',
        is_read_n: 'Non Letto',
        notice: 'Notifica',
        notice_type: 'Tipo di Notifica',
        notice_time: 'Data della Notifica',
        notice_msg: 'Messaggio della Notifica',
        operation_change_review: 'Revisione Cambio Operazione',
        review_returned: 'Recensioni in Ritorno',
        bo_cancel_to_review: 'Annullamento BO in Attesa di Revisione',
    },
};
