import type { Locales } from '../composables/i18n';
import { useCommonStore } from '~/stores/common';
import { i18nGlobal } from '~/utils/i18n';
import { router as globalRouter, routeMap } from '~/utils/router';
import { commonRequests } from '~/requests/common/common.request';
import { resHandler } from '~/utils/http/handler';
import { globalMessage } from '~/utils/discreteApi';
import type { CreateAndUpdateDateProps } from '~/requests/common/types';

export type Dept = {
    deptId: number;
    deptCode: number;
    parentId: number;
    deptLevels: string;
    deptName: string;
    orderNum: number;
    leader: string;
    phone: string;
    email: string;
    // 1正常0停用
    status: string;
    parentName: string;
    children: Dept[];
    // dict 1008
    deptType: string;
} & CreateAndUpdateDateProps;

export type UserLangListItem = {
    langCode: Locales;
    langName: string;
};

export type Role = {
    roleId: number;
    roleName: string;
    roleKey: string;
    roleSort: number;
    // 数据范围（1：所有数据权限；2：自定义数据权限；3：本部门数据权限；4：本部门及以下数据权限；5：仅本人数据权限）
    dataScope: string;
    // 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示）
    menuCheckStrictly: boolean;
    // 部门树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ）
    deptCheckStrictly: boolean;
    // 1正常0停用
    status: number;
    // 0代表存在 1代表删除
    delFlag: number;
    flag: boolean;
    menuIds: number[];
    deptIds: number[];
    permissions: string[];
    remark: string;
    // 区分下系统1->POMS,2->SNW
    sysId: number;
} & CreateAndUpdateDateProps;

export type UserInfo = {
    deptType: string;
    userid: number;
    deptId: number;
    deptCode: number;
    username: string;
    realName: string;
    email: string;
    phoneNumber: string;
    sex: string;
    avatar: string;
    dept: Dept;
    roles: Role[];
    roleIds: number[];
    postIds: number[];
    roleId: number;
    customerId: number;
    remark: string;
    childrenDeptIdList: number[];
    areaCode: number;
    // 区分下系统1->POMS,2->SNW
    sysId: number;
    idaasUser: number;
} & CreateAndUpdateDateProps;

export type UploadProgressListItem = { name: string; percentage: number; id: number };

const useUploadProgress = () => {
    const uploadItemIndex = ref(0);
    const showUploadProgress = ref(false);
    const uploadList = ref<UploadProgressListItem[]>([]);

    return {
        uploadItemIndex,
        uploadList,
        showUploadProgress,
    };
};

export const useUserStore = defineStore('user', () => {
    const commonStore = useCommonStore();
    const instanceRouter = useRouter();
    const { availableLocales } = useAvailableLocaleList();

    const userInfo = ref<UserInfo | null>(null);

    const authErrorDispatched = ref(false);

    const uploadProgress = useUploadProgress();

    const logoutCb = async (p: boolean) => {
        const router = instanceRouter ?? globalRouter;
        console.log(instanceRouter ? 'instanceRouter' : 'globalRouter');
        routeMap.clear();
        commonStore.formattedRoutes = [];
        userInfo.value = null;
        await commonStore.clearAuth();
        await router.push('/auth/login');
        if (p) return globalMessage.success(i18nGlobal.t('auth.logout_success'));
    };

    const logout = async () => {
        if (!commonStore.authToken) {
            await logoutCb(true);
            return;
        }
        const res = await commonRequests.logout();
        const isSuccess = await resHandler(res);
        if (isSuccess) {
            await logoutCb(true);
        }
    };

    const getUserInfo = async () => {
        const router = instanceRouter ?? globalRouter;
        if (!userInfo.value) {
            // commonRequests.cdcAuth();
            return commonRequests.getUserInfo().then(async (res) => {
                await resHandler(res, {
                    onSuccess: ({ res }) => {
                        userInfo.value = res.user;
                        i18nGlobal.locale.value = res.userLang;
                        commonStore.locale = res.userLang;
                        availableLocales.value = res.userLangList;
                        // if (userInfo.value.idaasUser === 1) commonRequests.cdcAuth();
                    },
                });
            });
        }
    };

    return {
        logout,
        logoutCb,
        userInfo,
        getUserInfo,
        ...uploadProgress,
        authErrorDispatched,
    };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
