import type { NotificationProviderProps } from 'naive-ui';

export const useAppNotification = createGlobalState(() => {
    const placement = ref<NotificationProviderProps['placement']>('top');

    const temporarilyChangePlacement = (
        targetPlacement: NotificationProviderProps['placement'],
        cb: (params: { onAfterLeave: () => void }) => void,
    ) => {
        const onAfterLeave = () => {
            placement.value = 'top';
        };

        const exec = () => {
            placement.value = targetPlacement;
            nextTick(cb.bind(null, { onAfterLeave }));
        };

        return {
            onAfterLeave,
            exec,
        };
    };

    return {
        placement,
        temporarilyChangePlacement,
    };
});
